<template>
  <v-card class="h-100">
    <v-card-title>
      Number of tickets
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6" sm="4" class="text-center">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="10"
            :value="items.totalAvailableTicketsPercent"
            color="primary"
          >
            <b>{{ items.totalTicketSold }}</b>
          </v-progress-circular>

          <div class="mt-2">Tickets Sold</div>
        </v-col>
        <v-col cols="6" sm="4" class="text-center">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="10"
            :value="items.remainingTicketCountPercent"
            color="primary"
          >
            <b>{{ items.totalRemainingTickets }}</b>
          </v-progress-circular>

          <div class="mt-2">Tickets Remaining</div>
        </v-col>
        <v-col cols="12" sm="4" class="position-relative">
          <div class="center text-center w-100">
            <div class="text-bold">Cash Taken</div>
            <div class="primary--text text-h5 text-bold">{{ items.totalPurchase | currency }}</div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    items: Object,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        { text: 'Amount', value: 'payoutTotal', sortable: false },
        { text: 'Uses', value: 'tickets', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
    };
  },
};
</script>
