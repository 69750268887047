<template>
  <div v-if="isDataLoaded">
    <br />

    <revenue :payouttotal="data.currentMonthPayout" />

    <br />

    <v-row>
      <v-col cols="12" sm="7">
        <number-of-tickets :items="data.ticketsData" />
      </v-col>

      <v-col cols="12" sm="5">
        <featured-events :items="data.feauredEvents" />
      </v-col>
    </v-row>

    <br />

    <top-selling-events />

    <br />

    <upcoming-events />

        <br />

    <v-row>
      <v-col cols="12" sm="12">
        <sales :limit="5" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PromoterEventService } from '@services';

import Revenue from './revenue';
import UpcomingEvents from './upcoming-events';
import FeaturedEvents from './featured-events';
import NumberOfTickets from './number-of-tickets';
import TopSellingEvents from './top-selling-events';
import Sales from './sales.vue';

export default {
  components: {
    Revenue,
    FeaturedEvents,
    NumberOfTickets,
    UpcomingEvents,
    TopSellingEvents,
    Sales
  },
  data() {
    return {
      isDataLoaded: false,
      data: {
        ticketsData: {},
        feauredEvents: [],
      },
    };
  },

  created() {
    this.$store.set('app/state', {});
    this.getEvents();
  },

  methods: {
    async getEvents() {
      const response = await PromoterEventService.getDashboardEvents();
      if (response) {
        this.data = response;
        this.data.ticketsData.totalAvailableTicketsPercent = this.data.ticketsData.totalTicketSold
          ? Math.round(
              (this.data.ticketsData.totalTicketSold * 100) /
                (this.data.ticketsData.totalTicketSold + this.data.ticketsData.totalRemainingTickets)
            )
          : 0;
        this.data.ticketsData.remainingTicketCountPercent = this.data.ticketsData.totalRemainingTickets
          ? Math.round(
              (this.data.ticketsData.totalRemainingTickets * 100) /
                (this.data.ticketsData.totalTicketSold + this.data.ticketsData.totalRemainingTickets)
            )
          : 0;
      }

      this.isDataLoaded = true;
    },
  },
};
</script>

<style lang="scss">
.v-data-table-small {
  td,
  th {
    font-size: 0.75rem !important;
    height: 32px !important;
  }
}
</style>
