<template>
  <v-card>
    <v-card-title>
      Recent Sales
      <v-spacer />
      <v-btn text @click="seeAllTrasactions()">See All</v-btn>
    </v-card-title>

    <v-data-table class="v-data-table-small" :headers="headers" :items="items" hide-default-footer>
      <template v-slot:[`item.amount`]="{ item }">
        <span>{{ item.amount | currency }}</span>
      </template>
      <template v-slot:[`item.seat`]="{ item }">
        <span>{{ item.seat || 'N/A' }}</span>
      </template>
      <template v-slot:[`item.ticketStatus`]="{ item }">
        <span>{{ item.ticketStatus || 'N/A' }}</span>
      </template>
      <template v-slot:[`item.actualTicketPrice`]="{ item }">
        <span>{{ item.actualTicketPrice | currency }}</span>
      </template>
      <template v-slot:[`item.bookingFee`]="{ item }">
        <span>{{ item.bookingFee | currency }}</span>
      </template>
      <template v-slot:[`item.eventStartAt`]="{ item }">
        <span>{{ item.eventStartAt | dateTime }}</span>
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        <span>{{ item.discount | currency }}</span>
      </template>
      <template v-slot:[`item.refundAmount`]="{ item }">
        <span>{{ item.refundAmount | currency }}</span>
      </template>
      <template v-slot:[`item.bookingDate`]="{ item }">
        <span>{{ item.createdAt | dateTime }}</span>
      </template>
      <template v-slot:[`item.seatCount`]="{ item }">
        <span>{{
          (item.seatCount || 0) - (item.refundCount || 0) >= 0 ? (item.seatCount || 0) - (item.refundCount || 0) : 0
        }}</span>
      </template>
      <template v-slot:[`item.refundCount`]="{ item }">
        <span>{{ item.refundCount || 0 }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';
export default {
  props: {
    limit: Number,
  },
  data() {
    return {
      query: {
        limit: null,
        page: 1,
        sort: 'date',
        isSalesReport: true,
        isOrderHistory: true,
      },
      headers: [
        {
          text: 'Name',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        { text: 'Event Name', value: 'eventTitle', sortable: false, width: '250px' },
        { text: 'Event Date', value: 'eventStartAt', sortable: false, width: '250px' },
        { text: 'Email', value: 'email', sortable: false, width: '250px' },
        { text: 'Number of tickets', value: 'seatCount', sortable: false, width: '200px' },
        { text: 'Booking Date', value: 'bookingDate', sortable: false, width: '200px' },
      ],
      items: [],
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      if (this.limit) {
          this.query.limit = this.limit;   
      }  
      const data = await PromoterEventService.getTickets(this.query);
      if (data) {
        this.items = data.tickets;
      }
    },
    seeAllTrasactions() {
      this.$next({ name: 'promoter.sales-reports' });
    },
  },
};
</script>
