<template>
  <v-card>
    <v-card-title>
      <v-row justify="space-between">
        <v-col cols="12" sm="7">
          Top Selling Events
        </v-col>

        <v-col cols="12" sm="4" md="3" xl="2">
          <v-text-field
            class="v-input--small"
            v-model="query.name"
            dense
            outlined
            hide-details
            placeholder="Search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-data-table class="v-data-table-small" :headers="headers" :items="items" hide-default-footer>
      <template v-slot:[`item.title`]="{ item }">
        <span>
          <a @click="goToEventDetails(item)">{{ item.title }}</a>
        </span>
      </template>
      <template v-slot:[`item.tickets`]="{ item }">
        <div class="d-flex">
          <div>{{ item.tickets.totalPurchaseCount }}/{{ item.tickets.totalAvailableCount }}</div>

          <v-progress-linear
            style="width:100px"
            class="my-auto ml-auto"
            :value="
              item.tickets.totalPurchaseCount
                ? Math.round((item.tickets.totalPurchaseCount / item.tickets.totalAvailableCount) * 100)
                : 0
            "
            rounded
            height="8"
            color="primary"
          ></v-progress-linear>
        </div>
      </template>
      <template v-slot:[`item.payoutTotal`]="{ item }">
        {{ item.payoutTotal | currency }}
      </template>
      <template v-slot:[`item.startsAt`]="{ item }">
        {{ item.startsAt | dateTime }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ item.status | capitalize }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';
export default {
  data() {
    return {
      query: {
        name: '',
        isTopSelling: true,
      },
      items: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'title',
          width: '200px',
        },
        { text: 'Tickets', value: 'tickets', align: 'center', sortable: false, width: '150px' },
        { text: 'Status', value: 'status', align: 'center', sortable: false, width: '100px' },
        { text: 'Payout Total', value: 'payoutTotal', align: 'center', sortable: false, width: '150px' },
        { text: 'Payout Status', value: 'payoutStatus', align: 'center', sortable: false, width: '150px' },
        { text: 'Start Date', value: 'startsAt', align: 'center', sortable: false, width: '150px' },
      ],
    };
  },
  created() {
    this.getEvents();
  },
  methods: {
    async getEvents() {
      const data = await PromoterEventService.get(this.$user?.organiser, this.query);
      if (data) {
        this.items = data.events;
      }
    },
    goToEventDetails(item) {
      this.$next({ name: 'promoter.events.dashboard', params: { id: item.slug } });
    },
  },
  watch: {
    'query.name': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.name && this.query.name.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.name) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
  },
};
</script>
