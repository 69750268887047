<template>
  <v-card class="h-100">
    <v-card-title>
      Featured Events
    </v-card-title>

    <v-data-table class="v-data-table-small" :headers="headers" :items="items" hide-default-footer>
      <template v-slot:[`item.tickets`]="{ item }">
        <div>{{ item.tickets.totalPurchaseCount }}/{{ item.tickets.totalAvailableCount }}</div>
      </template>
      <template v-slot:[`item.payoutTotal`]="{ item }">
        {{ item.payoutTotal | currency }}
      </template>
      <template v-slot:[`item.startsAt`]="{ item }">
        {{ item.startsAt | date }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ item.status | capitalize }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        { text: 'Amount', value: 'payoutTotal', sortable: false },
        { text: 'Uses', value: 'tickets', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
    };
  },
};
</script>
